ko.bindingHandlers.autocomplete = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var obj = valueAccessor(),
            input = $(element),
            widget, scrollBuffer = 80,//%
            pageSize = obj.pageSize ? obj.pageSize : 10,
            results = null, last = 0, _autoComplete
            lastPage = false, lastTerm = '';

        input.autocomplete({
            minLength: 0,
            autoFocus:false,
            position: { my: "left bottom", at: "left top", collision: "none" },
            open: function (event, ui) {
                widget.css('width', '325px');
                var top = input.position().top - widget.height();
     
                widget.css('top', top);
            },
            source: function (request, response) {
                var re = new RegExp("^" + request.term, 'i');

                if (!results || request.term != lastTerm) {
                    results = ko.utils.arrayFilter(obj.source(), function (item) {
                        return item[obj.text] && re.test(item[obj.text]) ;
                    });
                    last = 0;
                    lastPage = false;
                    lastTerm = request.term;
                }

                if (lastPage) {
                    return;
                }

                var display;

                if (results.length == 0) {
                    display = [];
                    var noResult = {};
                    noResult[obj.text] = "No Results";
                    display.push(noResult);
                 
                }
                else {
                
                    for (x = last; x <= pageSize + last && x < results.length; x++) {
                        if (x == last + pageSize || x == results.length -1) {
                            var display = results.slice(last, x + 1);
                            last = x;
                            break;
                        }
                    }
                }

                if (last == results.length - 1){
                    lastPage = true;
                    
                }


                if (last <= pageSize ) {
                    response(display);
                  
                } else {
                   
                    _autoComplete._renderMenu(_autoComplete.menu.element, display);
                    _autoComplete.menu.refresh();
                }
            },
            focus: function( event, ui ) {
                event.preventDefault();
                return false;
            },
            select: function (event, ui) {

                if (ui.item[obj.id]) {
                    obj.value(ui.item[obj.id]);
                    input.val(ui.item[obj.text]);

                    if (obj.change) {
                        obj.change(ui.item[obj.id]);
                    }
                }

                return false;
            },
            create: function (event, ui) {
                widget = $(this).autocomplete('widget');

                widget.css('max-height', '200px')
                     .css('overflow-y', 'auto')
                     .css('margin',0);

                input.focus(function () {
                    input.autocomplete("search", input.val());
                });

                widget.scroll(function (e) {
                 
                    if (scrollBuffer /100 * widget.prop('scrollHeight') <= widget.scrollTop() + widget.innerHeight()) {
                        input.autocomplete("search", input.val());
                    }           

                });
                
            },
            close: function (event, ui) {
                results = null;
            }
           
        })
        .data( "ui-autocomplete" )._renderItem = function( ul, item ) {
            return $( "<li>" )
              .append("<a>" + item[obj.text] + "</a>")
              .appendTo( ul );
        };

        _autoComplete = input.data("ui-autocomplete");
        
        //discard cached results when underlying datasource changes
        obj.source.subscribe(function (newValue) {
            results = null;
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element).autocomplete('destroy');
        });
    }
};