ko.bindingHandlers.animateVisible = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        $(element).toggle( ko.utils.unwrapObservable(valueAccessor())); 
    },
    // when element becomes visible or invisible, queue the animation. 
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContent) {
        var shouldShow = ko.utils.unwrapObservable(valueAccessor());
        shouldShow ? $(element).parent().queue(function () {
            $(element).fadeIn('fast', function () {
                $(element).parent().dequeue();
            });
        }) : $(element).parent().queue(function () {
            $(element).fadeOut('fast', function () {
                $(element).parent().dequeue();
            });
        });
    }
};